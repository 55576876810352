<template>
  <v-container>
    <v-row>
      <!-- Regular Dialog Section -->
      <v-col cols="5">
        <p class="section-title">Loading animation</p>
      </v-col>
    </v-row>
    <v-container>
      <v-row>
        <v-col
          class="d-flex flex-column py-2 my-3 mx-1 px-1 container-style"
          cols="5"
        >
          <div class="d-flex justify-center">
            <p class="mt-2 mr-2 style-label">Default</p>
            <v-btn
              icon
              color="caribbeanDark"
              @click="copyToClipboard('Default')"
            >
              <v-icon>mdi-code-tags</v-icon>
            </v-btn>
          </div>
          <div class="d-flex justify-center">
            <!-- Animation Component -->
            <LoadingAnimation />
            <!-- Animation Component -->
          </div>
        </v-col>
        <v-col
          class="d-flex flex-column py-2 my-3 mx-1 px-1 container-style"
          cols="5"
        >
          <div class="d-flex justify-center">
            <p class="mt-2 mr-2 style-label">Dense</p>
            <v-btn icon color="caribbeanDark" @click="copyToClipboard('Dense')">
              <v-icon>mdi-code-tags</v-icon>
            </v-btn>
          </div>
          <div class="d-flex justify-center mt-7">
            <!-- Dense Animation Component -->
            <LoadingAnimation dense />
            <!-- Dense Animation Component -->
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="d-flex flex-column py-2 my-3 mx-1 px-1 container-style"
          cols="10"
        >
          <div class="d-flex justify-center">
            <p class="mt-2 mr-2 style-label">Loading list</p>
            <v-btn
              icon
              color="caribbeanDark"
              @click="copyToClipboard('Loading list')"
            >
              <v-icon>mdi-code-tags</v-icon>
            </v-btn>
          </div>
          <div class="d-flex justify-center mt-4 mb-8">
            <!-- List Animation Component -->
            <LoadingListAnimation />
            <!-- List Animation Component -->
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import LoadingAnimation from '@/components/shared/customComponents/loadingAnimation/loading-animation.vue';
import LoadingListAnimation from '@/components/shared/customComponents/loadingAnimation/loading-list-animation.vue';

export default {
  name: 'LoadingAnimationWiki',
  components: {
    LoadingAnimation,
    LoadingListAnimation,
  },

  methods: {
    triggerToast(name) {
      const toastParams = {
        position: { top: true, right: true },
        timeout: 3000,
        messages: { success: `${name} copied to clipboard` },
        type: 'success',
      };

      this.$store.dispatch(
        'toastNotification/setupToastNotification',
        toastParams
      );
      this.$store.dispatch('toastNotification/showToastNotification', true);
    },

    selectedComponentHandler(name) {
      let selectedComponent = null;

      switch (name) {
        case 'Default':
          selectedComponent = `<LoadingAnimation />`;
          break;
        case 'Dense':
          selectedComponent = `<LoadingAnimation dense />`;
          break;
        case 'Loading list':
          selectedComponent = `<LoadingListAnimation />`;
          break;
      }
      return selectedComponent;
    },

    copyToClipboard(name) {
      let component = this.selectedComponentHandler(name);
      navigator.clipboard.writeText(component);
      this.triggerToast(name);
    },
  },
};
</script>

<style lang="scss" scoped>
.container-style {
  border: 1px dashed $grey-regular !important;
  border-radius: 5px;
}
</style>
